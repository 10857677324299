import React, { useState, useEffect } from 'react';
import Menu from '../Menu'


const ReportMySharesSymb = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sum, setSum] = useState(0);

    useEffect(() => {
        // Replace with your API endpoint
        

        const mytoken=sessionStorage.getItem('google_token');
        const apiUrl = 'https://app64.heliohost.us/api/getreports.php?oper=share_symb';
        //const apiUrl = 'http://localhost/api/getShareAlarms.php';

        
        fetch( apiUrl,{
            method:"GET",          
            headers:{
                "Content-Type": "application/json",
                "AuthCode" : mytoken
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                return response.json();
            })
            .then((data) => {
                setData(data);
                setLoading(false);
                //const totalSum = data.reduce((sum, item) => sum + 1*item.amounteuro, 0);                
                //setSum(totalSum.toFixed(2));

            })
            .catch((error) => {
                console.error("Error:", error);
                setError(error.message);
                setLoading(false);
            });
    }, []); // Empty dependency array means this runs once on component mount

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    return (
        <div>
            <Menu/>
            <h1>Data Table</h1>
            <table border="1" cellPadding="10" cellSpacing="0">
                <thead>
                    <tr>
                        <th>symb</th>
                        <th>amount</th>                        
                        <th>currency</th>                                            
                        <th>valnow</th>   
                        <th>valinvact</th>                           
                        <th>issearch</th>    
                        <th>valalarm</th>   
                        <th>procent</th>   
                        <th>configure</th>   
                                                                
                    </tr>
                </thead>
                <tbody>
                    {data.map((item) => (
                        <tr key={item.symb}>                            
                             <td>{item.symb}</td>
                            <td>{item.amount}</td> 
                            <td>{item.currency}</td>
                            <td>{item.valnow}</td>
                            <td>{item.valinvact}</td>                            
                            <td>{item.valalarm}</td>
                            <td>{item.idsearch}</td>                            
                            <td>{item.procent}</td>
                            <td><a href={`/sharealarmedit/${item.idsearch}`}>{item.idsearch}</a></td>
                            
                        </tr>
                    ))}
                </tbody>
            </table>
            <div>
                <b></b>
            </div>
        </div>
    );
};


export default ReportMySharesSymb