
import Menu from '../Menu'
import React, { useState, useEffect } from 'react';

const ShareAlarms = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Replace with your API endpoint
        

        const mytoken=sessionStorage.getItem('google_token');
        const apiUrl = 'https://app64.heliohost.us/api/getShareAlarms.php';
        //const apiUrl = 'http://localhost/api/getShareAlarms.php';

        
        fetch( apiUrl,{
            method:"GET",          
            headers:{
                "Content-Type": "application/json",
                "AuthCode" : mytoken
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                return response.json();
            })
            .then((data) => {
                setData(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error:", error);
                setError(error.message);
                setLoading(false);
            });
    }, []); // Empty dependency array means this runs once on component mount

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    return (
        <div>
            <Menu/>
            <h1>Data Table</h1>
            <table border="1" cellPadding="10" cellSpacing="0">
                <thead>
                    <tr>
                        <th>ID1</th>
                        <th>Symb</th>
                        <th>Val</th>
                        <th>LastVal</th>
                        <th>Typesearch</th>  
                        <th>symbXTB</th>   
                        <th>Procent</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item) => (
                        <tr key={item.id}>
                            <td><a href={`/sharealarmedit/${item.id}`}>{item.id}</a></td>                            
                            <td>{item.symb}</td>
                            <td>{item.val}</td>
                            <td>{item.lastval}</td>
                            <td>{item.typesearch}</td>
                            <td>{item.symbXTB}</td>
                            <td>{item.procent}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ShareAlarms;
